import ropstenChainIcon from '../assets/images/chain-logos/ropsten-chain.png'
import maticChainIcon from '../assets/images/chain-logos/polygon-logo.svg'
import bscChainIcon from '../assets/images/chain-logos/bsc-chain.png'
import avaxChainIcon from '../assets/images/chain-logos/avax.svg'
import maticAssetIcon from '../assets/images/chain-logos/matic-chain.png'
import coinBaseChainIcon from '../assets/images/chain-logos/coin-base.svg';
import arbitrum from '../assets/images/chain-logos/arbitrum.svg';
import mantal from '../assets/images/chain-logos/mantal.jpeg';
import scroll from '../assets/images/chain-logos/scroll_logo.png';
const ethers = require("ethers");



export enum ChainId {
  MAINNET = "1",
  ROPSTEN = "3",
  RINKEBY = "4",
  GORELI = "5",
  KOVAN = "42",
  POLYGON = "137",
  MUMBAI = "80001",
  OKEX = "66",
  ARBITRUM = "421613",
  FANTOM = "250",
  OPTIMISM = "10",
  XDAI = "100",
  BSC = "56",
  AVALANCHE = "43114",
  FUJI = "43113",
  CRONOS = "25",
  AURORA = "1313161554",
  HARMONY = "1666600000",
  KAVA = "2222",
  MOONBEAM = "1284",
  BSC_TESTNET = "97",
  ROUTER_TESTNET = "router_9601-1",
  COIN_BASE = "84531",
  NEAR_TESTNET = "near-testnet",
  SCROLL_TESTNET = "534353",
  MANTLE_TESTNET = "5001",
  X1_TESTNET = "195",
  KYOTO_TESTNET = "1998",
  ARTHERA_TESTNET = "10243"
}

export function getRequestMetadata(
  destGasLimit: number,
  destGasPrice: number,
  ackGasLimit: number,
  ackGasPrice: number,
  relayerFees: string,
  ackType: number,
  isReadCall: boolean,
  asmAddress: string
): string {
  return ethers.utils.solidityPack(
    [
      "uint64",
      "uint64",
      "uint64",
      "uint64",
      "uint128",
      "uint8",
      "bool",
      "string",
    ],
    [
      destGasLimit,
      destGasPrice,
      ackGasLimit,
      ackGasPrice,
      relayerFees,
      ackType,
      isReadCall,
      asmAddress,
    ]
  );
}

export function getRequestMetadataU8(
  destGasLimit: number,
  destGasPrice: number,
  ackGasLimit: number,
  ackGasPrice: number,
  relayerFees: string,
  ackType: number,
  isReadCall: boolean,
  asmAddress: string
): Uint8Array {
  const x = ethers.utils.solidityPack(
    [
      "uint64",
      "uint64",
      "uint64",
      "uint64",
      "uint128",
      "uint8",
      "bool",
      "string",
    ],
    [
      destGasLimit,
      destGasPrice,
      ackGasLimit,
      ackGasPrice,
      relayerFees,
      ackType,
      isReadCall,
      asmAddress,
    ]
  );
  const y = Uint8Array.from(Buffer.from(x.slice(2), "hex"));
  return y;
}

export function getRequestMetadataBase64(
  destGasLimit: number,
  destGasPrice: number,
  ackGasLimit: number,
  ackGasPrice: number,
  relayerFees: string,
  ackType: number,
  isReadCall: boolean,
  asmAddress: string
): string {
  const x = ethers.utils.solidityPack(
    [
      "uint64",
      "uint64",
      "uint64",
      "uint64",
      "uint128",
      "uint8",
      "bool",
      "string",
    ],
    [
      destGasLimit,
      destGasPrice,
      ackGasLimit,
      ackGasPrice,
      relayerFees,
      ackType,
      isReadCall,
      asmAddress,
    ]
  );
  const y = Buffer.from(x.slice(2), "hex");
  return y.toString("base64");
}

export function getRequestMetadataArray(
  destGasLimit: number,
  destGasPrice: number,
  ackGasLimit: number,
  ackGasPrice: number,
  relayerFees: string,
  ackType: number,
  isReadCall: boolean,
  asmAddress: string
): Array<number> {
  const x = ethers.utils.solidityPack(
    [
      "uint64",
      "uint64",
      "uint64",
      "uint64",
      "uint128",
      "uint8",
      "bool",
      "string",
    ],
    [
      destGasLimit,
      destGasPrice,
      ackGasLimit,
      ackGasPrice,
      relayerFees,
      ackType,
      isReadCall,
      asmAddress,
    ]
  );
  const y = Array.from(Buffer.from(x.slice(2), "hex"));
  return y;
}

export const chainLogos: {
  [key: string]: {
    networkLogo: string;
    assetLogo: string;
  };
} = {
  [ChainId.MUMBAI]: {
    networkLogo: maticChainIcon,
    assetLogo: maticAssetIcon,
  },
  [ChainId.BSC_TESTNET]: {
    networkLogo: bscChainIcon,
    assetLogo: bscChainIcon,
  },
  [ChainId.FUJI]: {
    networkLogo: avaxChainIcon,
    assetLogo: avaxChainIcon,
  },
  [ChainId.GORELI]: {
    networkLogo: ropstenChainIcon,
    assetLogo: ropstenChainIcon,
  },
  [ChainId.COIN_BASE]: {
    networkLogo: coinBaseChainIcon,
    assetLogo: coinBaseChainIcon,
  },
  [ChainId.ROUTER_TESTNET]: {
    networkLogo:
      "https://raw.githubusercontent.com/router-protocol/router-widget/main/route-coin.svg",
    assetLogo:
      "https://raw.githubusercontent.com/router-protocol/router-widget/main/route-coin.svg",
  },
  [ChainId.NEAR_TESTNET]: {
    networkLogo:
      "https://cryptologos.cc/logos/near-protocol-near-logo.svg?v=025",
    assetLogo: "https://cryptologos.cc/logos/near-protocol-near-logo.svg?v=025",
  },
  [ChainId.ARBITRUM]: {
    networkLogo: arbitrum,
    assetLogo: arbitrum,
  },
  [ChainId.SCROLL_TESTNET]: {
    networkLogo: scroll,
    assetLogo: scroll,
  },
  [ChainId.MANTLE_TESTNET]: {
    networkLogo: mantal,
    assetLogo: mantal,
  },
  [ChainId.X1_TESTNET]: {
    networkLogo: "https://seeklogo.com/images/O/okex-logo-5E39CD0FB6-seeklogo.com.png",
    assetLogo: "https://seeklogo.com/images/O/okex-logo-5E39CD0FB6-seeklogo.com.png",
  },
  [ChainId.KYOTO_TESTNET]: {
    networkLogo: "https://kyotoprotocol.io/hubfs/logo-window-1.png",
    assetLogo: "https://kyotoprotocol.io/hubfs/logo-window-1.png",
  },
  [ChainId.ARTHERA_TESTNET]: {
    networkLogo: "https://avatars.githubusercontent.com/u/125429157?s=200&v=4",
    assetLogo: "https://avatars.githubusercontent.com/u/125429157?s=200&v=4",
  }
};

// {
//     "chains": [
//       {
//         "name": "Mumbai Testnet",
//         "type": "ethereum",
//         "networkId": "80001",
//         "id": "0",
//         "endpoint": "https://rpc-mumbai.matic.today/",
//         "socket": "wss://matic-testnet-archive-ws.bwarelabs.com",
//         "gatewayContract": "0x8EA05371Eb360Eb79c295375CB2cCE9191EFdaD0",
//         "PingPong": "0xf6e4c0FA82375d7542Dd37855f610C567D6f09a6",
//         "XERC1155": "0x7F4459a68AE7C0546812Ca178EE2B291d7366F99"
//       },
//       {
//         "name": "Fuji",
//         "type": "ethereum",
//         "networkId": "43113",
//         "id": "1",
//         "endpoint": "https://api.avax-test.network/ext/bc/C/rpc",
//         "socket": "wss://api.avax-test.network/ext/bc/C/ws",
//         "gatewayContract": "0x517f256cC48145c25C27cf453F6f5006e5266543",
//         "PingPong": "0x3A7F5bc1a86c5553B2C59a637f1368d9ef4C56Cc",
//         "XERC1155": "0xAdC1247871b11eaaEb498e8D7F6A769B42460022"
//       },
//       {
//         "name": "BSC Testnet",
//         "type": "ethereum",
//         "networkId": "97",
//         "id": "2",
//         "endpoint": "https://data-seed-prebsc-1-s1.binance.org:8545/",
//         "socket": "",
//         "gatewayContract": "0x6988b6998292cD0D813cA5c5a747144a00e7f911",
//         "PingPong": "0x56b2fc450BfE527E19772688aB203D13060505C7",
//         "XERC1155": ""
//       },
//       {
//         "name": "Goerli Testnet",
//         "type": "ethereum",
//         "networkId": "5",
//         "id": "3",
//         "endpoint": "https://goerli.infura.io/v3/",
//         "socket": "",
//         "gatewayContract": "0x4690f9a1F0C84C17A56E63D92d220764e974325f",
//         "PingPong": "0x6378693Ae65C151ca8aa78149dEabf316e58792C",
//         "XERC1155": ""
//       }
//     ]
//   }

// {
//   "name": "Near Testnet",
//   "type": "near",
//   "networkId": "testnet",
//   "id": "6",
//   "endpoint": "https://rpc.testnet.near.org",
//   "socket": "",
//   "gatewayContract": "gateway.routerprotocol.testnet",
//   "PingPong": "ping-pong.routerprotocol.testnet",
//   "XERC1155": ""
// },