import React, { useState } from "react";
import "./App.css";
import Content, { RedirectPathToHomeOnly } from "./components/Content";
import Headers from "./components/Headers";
import chainLookUp from "./config/chainLookUp";
import { NetworkType } from "./config/network";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LogRocket from "logrocket";
import { ErrorBoundary } from "react-error-boundary";

LogRocket.init('xv2yfn/ping-pong-lglxs', {
  console: {
    isEnabled: false,
  },
});

declare global {
  interface Window {
    //@ts-ignore
    ethereum: any;
    modalProvider: any;
    walletModalProvider: any;
    walletClient: any;
  }
}

if (!!window.ethereum) {
  //@ts-ignore
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function App() {
  const [, setNetworkId] = useState("");
  const [, setCurrentNetwork] = useState<NetworkType | "">(
    chainLookUp["421613"]
  );
  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <div className="App">
        <Headers
          setNetworkId={setNetworkId}
          setCurrentNetwork={setCurrentNetwork}
        />
        <BrowserRouter>
          <Switch>
            <Route exact strict path="/" component={Content} />
            <Route exact strict path="/:userAddress" component={Content} />
            {/* <Route component={RedirectPathToHomeOnly} /> */}
          </Switch>
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
}

export default App;
