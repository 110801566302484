import { DEFAULT_GAS_PRICE } from "@routerprotocol/router-chain-sdk-ts";
import { ethers } from "ethers";
import { CustomChainType } from "../components/Wallet/types";
import { NetworkType } from "../config/network";

const fetchEVMGas = async (network: NetworkType) => {
  const destProvider = new ethers.providers.JsonRpcProvider(network.endpoint);
  let srcGasPrice;
  try {
    srcGasPrice = await destProvider.getGasPrice();
  } catch (e) {
    console.log("error in fetching gas price", e);
    return DEFAULT_GAS_PRICE.toString();
  }
  const newSrcGasPrice = srcGasPrice.mul(15).div(10);
  return newSrcGasPrice.toString();
};

export const getDestGasPrice = (network: NetworkType) => {
  const chainType = network.type;
  switch (chainType) {
    case CustomChainType.ethereum:
      return fetchEVMGas(network);
    case CustomChainType.router:
      return DEFAULT_GAS_PRICE.toString();
    case CustomChainType.near:
      return DEFAULT_GAS_PRICE.toString();
    default:
      return DEFAULT_GAS_PRICE.toString();
  }
};
