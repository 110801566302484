import {
  getEndpointsForNetwork,
  getNetworkType,
} from "@routerprotocol/router-chain-sdk-ts";
import { PING_PONG_ENVIRONMENT } from "./config";

export const SANDBOX_API = process.env.NEXT_PUBLIC_SANDBOX_API as string;
if (SANDBOX_API === "") {
  throw new Error("NEXT_PUBLIC_SANDBOX_API not present in .env");
}

export const NFT_ROUTER_ADDRESS =
  "router1wug8sewp6cedgkmrmvhl3lf3tulagm9hnvy8p0rppz9yjw0g4wtqh7s56u";

export const DEFAULT_GAS_PRICE = 500000000000;
export const DEFAULT_GAS_LIMIT = 200000;

export enum LocalStorageKeys {
  SourceChainId = "srcChainId",
  DestinationChainId = "dstChainId",
  PingMessage = "imessage",
  RxAddress = "rxAddress",
}

export const ROUTER_GRPC = getEndpointsForNetwork(
  getNetworkType(PING_PONG_ENVIRONMENT)
).grpcEndpoint;
export const ROUTER_LCD = getEndpointsForNetwork(
  getNetworkType(PING_PONG_ENVIRONMENT)
).lcdEndpoint;

export const ROUTER_CHAIN_RPC = getEndpointsForNetwork(
  getNetworkType(PING_PONG_ENVIRONMENT.toLowerCase()),
).rpcEndpoint
