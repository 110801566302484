import { XMarkIcon } from "@heroicons/react/24/solid";
import { useWalletConnected, useWalletId } from "../../state/wallet/hooks";
import { walletConfigs } from "./configs/ProviderConfig";
import { useWallets } from "./hooks";
import styled from "styled-components";
import metamaskLogo from "../../assets/images/wallet-logos/metamask.svg";
import trustwallet from "../../assets/images/wallet-logos/TWT.svg";
import coin98 from "../../assets/images/wallet-logos/coin98.png";
import cosmostation from "../../assets/images/wallet-logos/cosmostation.png";
import frontier from "../../assets/images/wallet-logos/frontier.svg";
import { WalletId } from "./types";
import { injectedConnector } from "./configs/connectors";

interface Props {
  close: () => void;
}

const WalletListContainer = styled.div`
  max-width: 500px;
  color: white;
  padding: 15px;
  background-color: #0d223b;
  border-radius: 12px;
  font-family: "Inter", sans-serif;
  margin: auto 10px;
`;

const CloseIcon = styled(XMarkIcon)`
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: white;
`;

const Title = styled.div`
  height: fit-content;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
`;

const WalletListWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  overflow-y: scroll;
`;

const InjectiveWalletName = styled.div`
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const InjectiveWalletLogo = styled.img`
  height: 40px;
  width: 40px;
  @media (max-width: 960px) {
    height: 30px;
    width: 30px;
  }
  transition: all 0.2s ease-in-out;
`;

const Wallet = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const WalletDetails = styled.div`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 105px;
  :hover {
    background: #06070a;
    filter: contrast(1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
`;

const BrowserWallet = styled.div`
  margin-top: 10px;
  width: 100%;
  text-align: start;
  font-size: 12px;
  font-weight: 700;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  @media (max-width: 550px) {
    display: none;
  }
`;

const WalletList = ({ close }: Props) => {
  const [walletId] = useWalletId();
  // const [sourceChain] = useSourceChain();

  const { handleConnect, handleDisconnect } = useWallets();
  const [isWalletConnected] = useWalletConnected();

  const allWalletDetails = [
    {
      walletName: "Metamask",
      walletIcon: metamaskLogo,
    },
    {
      walletName: "TrustWallet",
      walletIcon: trustwallet,
    },
    {
      walletName: "Coin98 Finance",
      walletIcon: coin98,
    },
    {
      walletName: "Cosmostation",
      walletIcon: cosmostation,
    },
    {
      walletName: "Frontier",
      walletIcon: frontier,
    },
  ];

  console.log("calue", allWalletDetails);
  return (
    <>
      <WalletListContainer>
        <CloseIcon onClick={close} />
        <Title>Connect To</Title>
        <WalletListWrapper>
          <Wrapper>
            <BrowserWallet>Browser Wallets</BrowserWallet>
            <Wallet>
              {allWalletDetails.map((i) => (
                <WalletDetails
                  onClick={async (e) => {
                    if (isWalletConnected) {
                      await handleDisconnect({
                        id: WalletId.injected,
                        name: "Browser Wallets",
                        logoUri:
                          "https://routerscan.io/assets/MetamaskIcon.svg",
                        connector: injectedConnector,
                      });
                    }
                    handleConnect({
                      id: WalletId.injected,
                      name: "Browser Wallets",
                      logoUri: "https://routerscan.io/assets/MetamaskIcon.svg",
                      connector: injectedConnector,
                    });
                    close();
                    e.stopPropagation();
                  }}
                >
                  <InjectiveWalletLogo
                    src={i.walletIcon}
                    alt="Injective Wallet Logo"
                  />
                  <InjectiveWalletName>{i.walletName}</InjectiveWalletName>
                </WalletDetails>
              ))}
            </Wallet>
          </Wrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <BrowserWallet>Others Wallets</BrowserWallet>
            <Wallet>
              {walletConfigs.map(
                (wallet, index) =>
                  wallet.name !== "Browser Wallets" && (
                    <WalletDetails
                      key={index}
                      // backgroundColor={
                      //   isWidget?.toString() === "true" ? backgroundColor : ""
                      // }
                      onClick={async (e) => {
                        if (isWalletConnected) {
                          await handleDisconnect(
                            walletConfigs.filter(
                              (wallet) => wallet.id === walletId
                            )[0]
                          );
                        }
                        handleConnect(wallet);
                        close();
                        e.stopPropagation();
                      }}
                    >
                      <InjectiveWalletLogo
                        src={wallet.logoUri}
                        alt={wallet.name}
                      />
                      <InjectiveWalletName>{wallet.name}</InjectiveWalletName>
                    </WalletDetails>
                  )
              )}
            </Wallet>
          </div>
        </WalletListWrapper>
      </WalletListContainer>
    </>
  );
};

export default WalletList;
