import { WalletStrategy } from "@routerprotocol/router-chain-sdk-ts";
import { useCallback } from "react";
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from "../../hooks";
import {
  accountAddress,
  setAccountAddress,
  networkId,
  walletConnected,
  setWalletConnected,
  setNetworkId,
  walletId,
  setWalletId,
  showConnectWallet,
  setShowConnectWallet,
  chainType,
  setChainType,
} from "./slice";

export function useAccountAddress(): [string, (args: string) => void] {
  const currentAccountAddress = useSelector(accountAddress);
  const dispatch = useDispatch();

  const setCurrentAccountAddress = useCallback(
    (args: string) => dispatch(setAccountAddress(args)),
    [currentAccountAddress, dispatch]
  );

  return [currentAccountAddress, setCurrentAccountAddress];
}

export function useNetworkId(): [string, (args: string) => void] {
  const currentNetworkId = useSelector(networkId);
  const dispatch = useDispatch();

  const setCurrentNetworkId = useCallback(
    (args: string) => dispatch(setNetworkId(args)),
    [currentNetworkId, dispatch]
  );

  return [currentNetworkId, setCurrentNetworkId];
}

export function useWalletId(): [string, (args: string) => void] {
  const currentWalletId = useSelector(walletId);
  const dispatch = useDispatch();

  const setCurrentWalletId = useCallback(
    (args: string) => dispatch(setWalletId(args)),
    [currentWalletId, dispatch]
  );

  return [currentWalletId, setCurrentWalletId];
}

export function useWalletConnected(): [boolean, (args: boolean) => void] {
  const isWalletConnected = useSelector(walletConnected);
  const dispatch = useDispatch();

  const setIsWalletConnected = useCallback(
    (args: boolean) => dispatch(setWalletConnected(args)),
    [isWalletConnected, dispatch]
  );

  return [isWalletConnected, setIsWalletConnected];
}

export function useShowConnectWallet(): [boolean, (args: boolean) => void] {
  const isShowConnectWallet = useSelector(showConnectWallet);
  const dispatch = useDispatch();

  const setIsShowConnectWallet = useCallback(
    (args: boolean) => dispatch(setShowConnectWallet(args)),
    [isShowConnectWallet, dispatch]
  );

  return [isShowConnectWallet, setIsShowConnectWallet];
}

export function useChainType(): [string, (args: string) => void] {
  const currentChainType = useSelector(chainType);
  const dispatch = useDispatch();

  const setCurrentChainType = useCallback(
    (args: string) => dispatch(setChainType(args)),
    [currentChainType, dispatch]
  );

  return [currentChainType, setCurrentChainType];
}
