import React, { useEffect, useState } from "react";
import {
  useAccountAddress,
  useWalletConnected,
  useWalletId,
} from "../../state/wallet/hooks";
import ModalWrapper from "./ModalWrapper";
import AccountInfo from "./AccountInfo";
import WalletList from "./WalletList";
import { shortenAddress } from "./configs/utils";
import styled from "styled-components";
import { useWallets } from "./hooks";
import { walletConfigs } from "./configs/ProviderConfig";

type Props = {};

declare global {
  interface Window {
    walletClient: any;
    ethereum: any;
    frontier: any;
    cosmostation: any;
  }
}

const WalletBox = styled.div<{ connected: boolean }>`
  width: ${({ connected }) => (connected ? "150px" : "200px")};
  height: 35px;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c1c1db;
  background: #011627;
  border: 1px solid rgba(88, 88, 99, 0.24);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: "3px 3px 10px 4px rgba(0, 0, 0, 0.3)";
`;

const WalletComponent = ({}: Props) => {
  const [isWalletConnected] = useWalletConnected();
  const [accountAddress] = useAccountAddress();
  const [showWalletList, setShowWalletList] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [walletId] = useWalletId();
  const { handleConnect } = useWallets();

  useEffect(() => {
    if (!walletId) return;
    handleConnect(walletConfigs.filter((wallet) => wallet.id === walletId)[0]);
  }, []);

  return (
    <>
      <ModalWrapper
        open={showWalletList}
        onClose={() => setShowWalletList(false)}
      >
        <WalletList close={() => setShowWalletList(false)} />
      </ModalWrapper>
      <ModalWrapper
        open={showAccountInfo}
        onClose={() => setShowAccountInfo(false)}
      >
        <AccountInfo
          action={() => {
            setShowAccountInfo(false);
            setShowWalletList(true);
          }}
          close={() => setShowAccountInfo(false)}
        />
      </ModalWrapper>
      <WalletBox
        connected={isWalletConnected}
        onClick={() =>
          isWalletConnected ? setShowAccountInfo(true) : setShowWalletList(true)
        }
      >
        {isWalletConnected
          ? shortenAddress(accountAddress, 4)
          : "Connect Wallet"}
      </WalletBox>
    </>
  );
};

export default WalletComponent;
