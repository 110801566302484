import { CircularProgress } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import chainLookUp from "../config/chainLookUp";
import {
  fetchMessage,
  fetchMessageRouterChain,
  getAck,
  getAckRouterChain,
  getNearRequestId,
  nftData,
  readFromNear,
  sendMessage,
} from "../config/contractFunction";
import { NetworkType } from "../config/network";
import { chains } from "../config/network";
import { switchNetworkInMetamask } from "../utils/metamaskFunctions";
import MenuSelect from "./MenuSelect";
import {
  useAccountAddress,
  useNetworkId,
  useWalletConnected,
  useWalletId,
} from "../state/wallet/hooks";
import { Redirect, RouteComponentProps, useLocation } from "react-router-dom";
import { API_KEY, PING_PONG_ENVIRONMENT, SANDBOX_API } from "../config/config";
import ReactGA from "react-ga4";
import {
  ChainId,
  chainLogos,
  getRequestMetadataArray,
  getRequestMetadataBase64,
} from "../config/flag";
import OnConnect from "../assets/images/ConnectWallet.gif";
import AfterConnect from "../assets/images/ClaimNFT.gif";
import Banner from "../assets/images/AlreadyClaimed.gif";
import {
  DEFAULT_GAS_LIMIT,
  DEFAULT_GAS_PRICE,
  LocalStorageKeys,
  NFT_ROUTER_ADDRESS,
  ROUTER_GRPC,
  ROUTER_LCD,
} from "../config/constants";
import Play from "../assets/images/play.gif";
import {
  ChainGrpcWasmApi,
  Network,
  getEndpointsForNetwork,
  getRouterAddress,
  TxGrpcClient,
  EthereumChainId,
} from "@routerprotocol/router-chain-sdk-ts";
import MenuWrapper from "./MenuWrapper";
import CloseIcon from "@mui/icons-material/Close";
import { useWallets } from "./Wallet/hooks";
import { CustomChainType, WalletId } from "./Wallet/types";
import LogRocket from "logrocket";
import { getDestGasPrice } from "../utils/fetchGasPrice";
import { nearNetworkConfig } from "./Wallet/configs/nearConfig";

ReactGA.initialize("G-ZPZ4JX5YFE");
const { ethers } = require("ethers");

const Wrapper = styled.div`
  margin-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  margin-bottom: 100px;
`;

const LeftContent = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(88, 88, 99, 0.24);
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-shadow: "3px 3px 10px 4px rgba(0, 0, 0, 0.3)";
  background: #1a1b1c;
`;
const RightContent = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(88, 88, 99, 0.24);
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-shadow: "3px 3px 10px 4px rgba(0, 0, 0, 0.3)";
  background: #1a1b1c;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  text-align: start;
  font-size: 18px;
  margin: 10px 0;
`;
const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Message = styled.div<{ background: boolean }>`
  margin-top: 24px;
  padding: 10px;
  border: 1px solid #c3c5cb;
  border-radius: 10px;
  height: 100px;
  background: ${({ background }) => (background ? "#FFFFFF" : "lightgray")};
  margin-bottom: 20px;
`;

const TextArea = styled.textarea<{ background: boolean }>`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  background: ${({ background }) => (background ? "#FFFFFF" : "lightgray")};
`;

const SubmitButton = styled.div<{ btnColor: boolean }>`
  background: #ff3d00;
  opacity: ${({ btnColor }) => (btnColor ? "" : "0.5")};
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
`;

const Seperator = styled.div`
  margin: 20px 0;
  border: 1px solid rgba(88, 88, 99, 0.24);
`;

const Text = styled.text`
  font-size: 20px;
  margin-top: 20px;
  text-align: start;
`;

const Loader = styled.div`
  margin: 5px auto;
`;

const MessageBlock = styled.div`
  width: 90%;
  border-radius: 10px;
  padding: 20px;
  background: #252836;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
`;

const Leftcontent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Rightcontent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const NearReceipient = styled.input`
  padding: 10px 0;
  font-family: "Inter", sans-serif;
  width: 100%;
  font-size: 18px;
  text-align: left;
  color: white;
  background: none;
  border: none;
  border-bottom: 1px solid white;
  &:focus {
    outline: none;
  }
`;

export function RedirectPathToHomeOnly({ location }: RouteComponentProps) {
  return (
    <Redirect
      to={{
        ...location,
        pathname: "/",
      }}
    />
  );
}

const Content = ({
  match: {
    params: { userAddress },
  },
  history,
}: RouteComponentProps<{ userAddress?: string }>) => {
  const [paragraph, _setParagraph] = useState(
    typeof localStorage.getItem(LocalStorageKeys.PingMessage) === typeof null
      ? ""
      : (localStorage.getItem(LocalStorageKeys.PingMessage) as string)
  );
  const [accountAddress] = useAccountAddress();
  const [message, setMessage] = useState("");
  const [ack, setAck] = useState("");
  const [srcChain, _setSrcChain] = useState<NetworkType>(
    typeof localStorage.getItem(LocalStorageKeys.SourceChainId) === typeof null
      ? chainLookUp["421613"]
      : chainLookUp[
          localStorage.getItem(LocalStorageKeys.SourceChainId) as string
        ]
  );
  const [dstChain, _setDstChain] = useState<NetworkType>(
    typeof localStorage.getItem(LocalStorageKeys.DestinationChainId) ===
      typeof null
      ? chainLookUp["5001"]
      : chainLookUp[
          localStorage.getItem(LocalStorageKeys.DestinationChainId) as string
        ]
  );
  const [requestId, setRequestId] = useState("");
  const [loaderDstMessage, setLoaderDstMessage] = useState(false);
  const [loaderAck, setLoaderAck] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [userAddresses, setUserAddresses] = useState("");
  const [chainList, setChainList] = useState(chains);
  const [error, setError] = useState(false);
  const [executionStart, setExecutionStart] = useState(false);
  const [bannerLoader, setBannerLoader] = useState(false);
  const [isWalletConnected] = useWalletConnected();
  const [showNftClaimedBanner, setShowNftClaimedBanner] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");

  const endpoint = getEndpointsForNetwork(
    PING_PONG_ENVIRONMENT === Network.TestnetEu
      ? Network.TestnetEu
      : PING_PONG_ENVIRONMENT === Network.Testnet
      ? Network.Testnet
      : Network.AlphaDevnet
  ).grpcEndpoint;
  const wasmClient = new ChainGrpcWasmApi(endpoint);

  const [user_has_nft, setUser_has_nft] = useState<any>(undefined);
  const { handleSendTransaction } = useWallets();
  const [networkId] = useNetworkId();
  const [walletId] = useWalletId();
  const [receipientAddress, _setReceipientAddress] = useState(
    typeof localStorage.getItem(LocalStorageKeys.RxAddress) === typeof null
      ? ""
      : (localStorage.getItem(LocalStorageKeys.RxAddress) as string)
  );
  const [showReceipientAddress, setShowReceipientAddress] = useState(false);
  const setSrcChain = (chain: NetworkType) => {
    localStorage.setItem(LocalStorageKeys.SourceChainId, chain.networkId);
    _setSrcChain(chain);
  };
  const setDstChain = (chain: NetworkType) => {
    localStorage.setItem(LocalStorageKeys.DestinationChainId, chain.networkId);
    _setDstChain(chain);
  };
  const setParagraph = (message = "") => {
    localStorage.setItem(LocalStorageKeys.PingMessage, message);
    _setParagraph(message);
  };
  const setReceipientAddress = (message = "") => {
    localStorage.setItem(LocalStorageKeys.RxAddress, message);
    _setReceipientAddress(message);
  };
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const nearTransactionHash = useMemo(
    () => new URLSearchParams(location.search).get("transactionHashes"),
    [location]
  );

  const sourceProvider = useMemo(
    () => new ethers.providers.JsonRpcProvider(srcChain.endpoint),
    [srcChain]
  );
  const destinationProvider = useMemo(
    () => new ethers.providers.JsonRpcProvider(dstChain.endpoint),
    [dstChain]
  );

  const bottomPage = useRef(null);

  const scrollToBottomPage = () => {
    if (bottomPage.current) {
      //@ts-ignore
      bottomPage.current.scrollIntoView({ bottom: 0, behavior: "smooth" });
    }
  };

  const handleSubmit = async () => {
    console.log("srcChain.type", srcChain.type);
    if (!isWalletConnected) {
      alert(`Connet wallet`);
      return;
    }
    if (paragraph === "") {
      alert(`Write message to perform Ping Pong`);
      return;
    }
    if (
      walletId !== CustomChainType.near &&
      srcChain.type === CustomChainType.near
    ) {
      alert(`Switch to my near wallet`);
      return;
    } else if (
      walletId !== WalletId.injected &&
      srcChain.type === CustomChainType.router
    ) {
      alert(`Switch to Browser Wallet`);
      return;
    } else if (
      walletId === WalletId.near &&
      srcChain.type === CustomChainType.ethereum
    ) {
      alert(`Switch to EVM based wallet`);
      return;
    }
    if (networkId !== srcChain.networkId) {
      if (srcChain.type === CustomChainType.ethereum) {
        // alert(`Switch to ${srcChain.networkId} in your wallet`);
        // return;
        const isSwitched = await switchNetworkInMetamask(srcChain.id);
        if (!isSwitched) {
          alert(`Switch to ${srcChain.networkId} in your wallet`);
          return;
        }
      } else if (
        srcChain.type === CustomChainType.router &&
        networkId !== EthereumChainId.TestnetEvm.toString()
      ) {
        // alert(`Switch to ${EthereumChainId.TestnetEvm} in your wallet`);
        // return;
        const isSwitched = await switchNetworkInMetamask(srcChain.id);
        if (!isSwitched) {
          alert(`Switch to ${EthereumChainId.TestnetEvm} in your wallet`);
          return;
        }
      }
    }
    if (srcChain.type === CustomChainType.near) {
      if (receipientAddress === "") {
        alert("Enter nft receipient address");
        return;
      }
    }
    if (accountAddress === "") return;
    if (executionStart) return;
    if (message !== "" || ack !== "" || requestId !== "") {
      setMessage("");
      setAck("");
      setRequestId("");
    }
    if (!error) {
      ReactGA.event({
        category: "Sandbox Ping Pong",
        action: "user_click_on_custom_ping_pong_send",
      });
    }
    if (error) {
      ReactGA.event({
        category: "Generic Ping Pong",
        action: "user_click_on_generic_ping_pong_send",
      });
    }
    let tx;
    setExecutionStart(true);
    setLoaderDstMessage(true);
    scrollToBottomPage();
    try {
      if (srcChain.type === CustomChainType.ethereum) {
        const walletModalProvider = new ethers.providers.Web3Provider(
          window.walletClient
        );
        const walletModalProviderSigner = walletModalProvider.getSigner();
        if (walletModalProviderSigner) {
          const destGasPrice = await getDestGasPrice(dstChain);
          tx = await sendMessage({
            srcChainPingPongAddress:
              Object.keys(userDetails).length !== 0 && !error
                ? Object.entries(userDetails.deployedAddresses).filter(
                    ([key]) => key.includes(srcChain.networkId)
                  )[0][1]
                : srcChain.PingPong,
            provider: sourceProvider,
            message: paragraph,
            chainId: dstChain.networkId,
            destContractAddress:
              Object.keys(userDetails).length !== 0 && !error
                ? Object.entries(userDetails.deployedAddresses).filter(
                    ([key]) => key.includes(dstChain.networkId)
                  )[0][1]
                : dstChain.PingPong.toLowerCase(),
            signer: walletModalProviderSigner,
            destProvider: destinationProvider,
            destGasPrice: destGasPrice,
            srcChainId: srcChain.networkId,
          });
          const eventFilter = tx.events.filter(
            (i: any) => i.event === "NewPing"
          );
          const id = eventFilter[0].args[0].toString();
          setRequestId(id);
          setTransactionHash(tx.transactionHash);
          if (!tx) {
            setLoaderDstMessage(false);
          }
          console.log("tx--->", tx);
        }
      } else if (srcChain.type === CustomChainType.router) {
        const walletModalProvider = new ethers.providers.Web3Provider(
          window.walletClient
        );
        const walletModalProviderSigner = walletModalProvider.getSigner();
        if (walletModalProviderSigner) {
          const dstGasPrice = await getDestGasPrice(dstChain);
          const executionArgs = {
            ping: paragraph,
            dest_contract_address:
              Object.keys(userDetails).length !== 0 && !userDetails.message
                ? Object.entries(userDetails.deployedAddresses).filter(
                    ([key]) => key.includes(dstChain.networkId)
                  )[0][1]
                : dstChain.PingPong.toLowerCase(),
            dest_chain_id: dstChain.networkId,
            request_metadata: getRequestMetadataBase64(
              DEFAULT_GAS_LIMIT,
              parseInt(dstGasPrice),
              DEFAULT_GAS_LIMIT,
              DEFAULT_GAS_PRICE,
              "0",
              3,
              false,
              ""
            ),
          };
          console.log("executionArgs =>", executionArgs);
          const grpcClient = new TxGrpcClient(ROUTER_GRPC);
          tx = await handleSendTransaction({
            routerNetworkEnv: PING_PONG_ENVIRONMENT,
            //@ts-ignore
            routerContractAddress:
              Object.keys(userDetails).length !== 0 && !userDetails.message
                ? Object.entries(userDetails.deployedAddresses).filter(
                    ([key]) => key.includes(srcChain.networkId)
                  )[0][1]
                : srcChain.PingPong,
            routerExecuteMsg: {
              i_ping: executionArgs,
            },
            routerNodeUrl: ROUTER_LCD,
          });

          const txRes = await grpcClient.fetchTxPoll(tx.tx_response.txhash);
          console.log("txRes =>", txRes);
          setTransactionHash(txRes.txHash)
          //@ts-ignore
          let requestId = txRes.logsList[0].eventsList
            .filter((x: any) => x.type === "wasm-new_ping")[0]
            .attributesList.filter((x: any) => x.key === "request_id")[0].value;
          console.log("fetched requestId", requestId);
          setRequestId(requestId);
          if (!tx) {
            setLoaderDstMessage(false);
          }
          console.log("tx--->", tx);
        }
      } else if (srcChain.type === CustomChainType.near) {
        console.log("Near case hit");
        console.log("userDetails =>", userDetails);
        const dstGasPrice = await getDestGasPrice(dstChain);
        tx = await handleSendTransaction({
          methodName: "i_ping",
          args: {
            dest_chain_id: dstChain.networkId,
            destination_contract_address:
              Object.keys(userDetails).length !== 0 && !userDetails.message
                ? Object.entries(userDetails.deployedAddresses).filter(
                    ([key]) => key.includes(dstChain.networkId)
                  )[0][1]
                : dstChain.PingPong.toLowerCase(),
            str: paragraph,
            request_metadata: getRequestMetadataArray(
              DEFAULT_GAS_LIMIT,
              parseInt(dstGasPrice),
              DEFAULT_GAS_LIMIT,
              DEFAULT_GAS_PRICE,
              "0",
              1,
              false,
              ""
            ),
            recipient: receipientAddress,
          },
          gas: "30000000000000",
          deposit: "10000000000000000000000",
        });
        console.log("near tx =>", tx);
      }
    } catch (e) {
      setExecutionStart(false);
      console.log("error in send message-->", e);
      setLoaderDstMessage(false);
      LogRocket.log("Error in handleSubmit(ping) function", {
        error: JSON.stringify(e),
      });
    }
  };

  const getAcknowledement = async () => {
    try {
      let acko =
        srcChain.type === CustomChainType.ethereum
          ? await getAck({
              requestId: requestId,
              srcChainPingPongAddress:
                Object.keys(userDetails).length !== 0 && !error
                  ? Object.entries(userDetails.deployedAddresses).filter(
                      ([key]) => key.includes(srcChain.networkId)
                    )[0][1]
                  : srcChain.PingPong,
              provider: sourceProvider,
            })
          : srcChain.type === CustomChainType.router
          ? await getAckRouterChain(
              ROUTER_GRPC,
              Object.keys(userDetails).length !== 0
                ? Object.entries(userDetails.deployedAddresses).filter(
                    ([key]) => key.includes(srcChain.networkId)
                  )[0][1]
                : srcChain.PingPong,
              requestId
            )
          : await readFromNear(
              accountAddress,
              srcChain.PingPong,
              "get_ack_from_destination",
              {
                request_id: parseInt(requestId),
              }
            );
      if (acko) {
        setLoaderAck(false);
        setAck(acko);
        setExecutionStart(false);
        if (user_has_nft === false) {
          setShowNftClaimedBanner(true);
        }
        return true;
      }
    } catch (e) {
      console.log("error in getting acknowledgement", e);
      LogRocket.log("Error in getAcknowledement function", {
        error: JSON.stringify(e),
      });
      setLoaderAck(false);
    }
    return false;
  };

  const dstMessage = async (_requestId: any = null) => {
    try {
      let messageFromSrc =
        dstChain.type === CustomChainType.ethereum
          ? await fetchMessage({
              destContractAddress:
                Object.keys(userDetails).length !== 0 && !error
                  ? Object.entries(userDetails.deployedAddresses).filter(
                      ([key]) => key.includes(dstChain.networkId)
                    )[0][1]
                  : dstChain.PingPong.toLowerCase(),
              provider: destinationProvider,
              requestId: _requestId ?? requestId,
              chainId: srcChain.networkId,
            })
          : dstChain.type === CustomChainType.router
          ? await fetchMessageRouterChain(
              ROUTER_GRPC,
              Object.keys(userDetails).length !== 0
                ? Object.entries(userDetails.deployedAddresses).filter(
                    ([key]) => key.includes(dstChain.networkId)
                  )[0][1]
                : dstChain.PingPong.toLowerCase(),
              srcChain.networkId,
              requestId
            )
          : await readFromNear(
              nearNetworkConfig.accountId,
              dstChain.PingPong,
              "get_ping_from_source",
              {
                src_chain_id: srcChain.networkId,
                request_id: parseInt(requestId),
              }
            );
      console.log("fetch meesage on destination side", message);
      if (messageFromSrc) {
        setLoaderDstMessage(false);
        setMessage(messageFromSrc);
        setLoaderAck(true);
        return true;
      }
    } catch (e) {
      console.log("error in fetching message:", e);
      LogRocket.log("Error in dstMessage function", {
        error: JSON.stringify(e),
      });
      setLoaderDstMessage(false);
    }
    return false;
  };

  useEffect(() => {
    let interval: any;
    if (message !== "" && ack === "") {
      scrollToBottomPage();
      interval = setInterval(async () => {
        let value = await getAcknowledement();
        if (value) {
          console.log("in ack");
          clearInterval(interval);
        }
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [ack, message]);

  useEffect(() => {
    let interval: any;
    if (requestId && message === "") {
      interval = setInterval(async () => {
        let value = await dstMessage();
        if (value) {
          console.log("In dst");
          clearInterval(interval);
        }
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [requestId, message]);

  const changeChain = async (e: string) => {
    await switchNetworkInMetamask(e);
  };

  useEffect(() => {
    if (!accountAddress) return;
    if (walletId !== WalletId.injected) return;
    if (Number(srcChain.networkId) !== Number(networkId)) {
      changeChain(srcChain.id);
    }
  }, [accountAddress]);

  //   useEffect(() => {
  //     const items = localStorage.getItem("userAddress");
  //     if (items) {
  //       setUserAddresses(items);
  //     }
  //   }, []);

  useEffect(() => {
    if (userAddresses) {
      setUserAddresses(userAddresses);
      history.push(`/${userAddresses}`);
    }
  }, [userAddresses]);

  useEffect(() => {
    if (userAddress) {
      setUserAddresses(userAddress);
    }
  }, [userAddress]);

  useEffect(() => {
    if (userAddresses) {
      setLoader(true);
      const handleClick = async () => {
        try {
          const data = await fetch(
            `${SANDBOX_API}/fetchDetails?userAddress=${userAddresses}`,
            {
              method: "GET",
              headers: {
                "x-api-key": API_KEY,
              },
            }
          );
          const keys1 = await data.json();
          setUserDetails(keys1);
          console.log("keys", keys1);
          setLoader(false);
        } catch (e) {
          console.log("Error in fetchDetails", e);
          LogRocket.log("Error in handleClick(sandbox fetchDetails) function", {
            error: JSON.stringify(e),
          });
        }
      };
      handleClick();
    }
  }, [userAddresses]);
  useEffect(() => {
    if (userDetails.error || userDetails.message) {
      setChainList(chains);
      setError(true);
      history.push("/");
    } else if (Object.keys(userDetails).length !== 0) {
      //   localStorage.setItem("userAddress", userAddresses);
      const filterChain = chains.filter((i) =>
        Object.keys(userDetails.deployedAddresses).includes(i.networkId)
      );
      setChainList(filterChain);
      setSrcChain(filterChain[0]);
      setDstChain(filterChain[1]);
    }
  }, [userDetails]);

  useEffect(() => {
    ReactGA.event({
      category: "Ping Pong Src Chain",
      action: `src_chain_selected_${srcChain.name}`,
    });
  }, [srcChain]);

  useEffect(() => {
    ReactGA.event({
      category: "Ping Pong Destination Chain",
      action: `dst_chain_selected_${dstChain.name}`,
    });
  }, [dstChain]);

  //   const handleLocalStorage = () => {
  //     localStorage.clear();
  //     history.push("/");
  //     window.location.reload();
  //   };

  useEffect(() => {
    if (!isWalletConnected) {
      setTimeout(() => {
        setBannerLoader(true);
      }, 2000);
    }
  }, [isWalletConnected]);

  useEffect(() => {
    const fetch = async () => {
      const routerAccountAddress =
        srcChain.type === CustomChainType.near
          ? getRouterAddress(receipientAddress)
          : getRouterAddress(accountAddress);
      const query = {
        extension: {
          msg: {
            get_is_n_f_t_received: {
              address: `${routerAccountAddress}`,
            },
          },
        },
      };
      let data: any;
      try {
        data = await nftData({
          wasmClient: wasmClient,
          query: query,
          nftRouterAddress: NFT_ROUTER_ADDRESS,
        });
        console.log("data -->", data);
        setUser_has_nft(data?.data);
      } catch (e) {
        console.log("error in fetching nft data", e);
        LogRocket.log("Error in nftData function", {
          error: JSON.stringify(e),
        });
      }
    };
    if (accountAddress && wasmClient && executionStart === false) {
      fetch();
    }
  }, [accountAddress, user_has_nft, executionStart]);

  //If Transaction sent from Near
  useEffect(() => {
    if (!nearTransactionHash || nearTransactionHash === "") return;
    if (accountAddress === "" || srcChain.type !== CustomChainType.near) return;
    setTransactionHash(nearTransactionHash);
    console.log("came for second leg");
    setLoaderDstMessage(true);
    let interval: any;
    const completeNearSecondLeg = async () => {
      const _requestId = await getNearRequestId(
        nearTransactionHash,
        accountAddress,
        srcChain.endpoint
      );
      console.log("found near requestId =>", _requestId);
      console.log("Message =>", message);
      setRequestId(String(_requestId));
      if (_requestId && message === "") {
        const fetchDstNear = async () => {
          let value = await dstMessage(_requestId);
          if (value) {
            console.log("In dst");
            clearInterval(interval);
          }
        };
        fetchDstNear();
        interval = setInterval(fetchDstNear, 10000);
      }
    };
    completeNearSecondLeg();
    return () => clearInterval(interval);
  }, [nearTransactionHash, accountAddress]);

  useEffect(() => {
    if (srcChain.networkId === ChainId.NEAR_TESTNET) {
      setShowReceipientAddress(true);
    } else {
      setShowReceipientAddress(false);
    }
  }, [srcChain]);

  return (
    <>
      <MenuWrapper
        open={showNftClaimedBanner}
        onClose={() => setShowNftClaimedBanner(false)}
      >
        <div style={{ position: "relative" }}>
          <CloseIcon
            style={{
              position: "absolute",
              right: "10px",
              color: "white",
              top: "10px",
              cursor: "pointer",
            }}
            onClick={() => setShowNftClaimedBanner(false)}
          />
        </div>
        <div
          style={{
            width: "300px",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            background: "#252836",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              textAlign: "center",
              color: "white",
              fontWeight: "bold",
            }}
          >
            NFT Claimed!
          </div>
          <img
            src={Play}
            height={"120px"}
            width={"120px"}
            style={{ margin: "30px auto" }}
          />
          <div
            style={{
              fontSize: "14px",
              marginTop: "10px",
              textAlign: "center",
              color: "white",
            }}
          >
            The NFT will be deposited into your Router chain wallet in a few
            minutes.
          </div>
          <div
            style={{
              width: "160px",
              padding: "10px",
              margin: "0 auto",
              marginTop: "30px",
              color: "white",
              background: "#ff3d00",
              borderRadius: "8px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              window.open(
                `https://explorer.testnet.routerchain.dev/address/${
                  srcChain.type === CustomChainType.near
                    ? getRouterAddress(receipientAddress)
                    : getRouterAddress(accountAddress)
                }`
              )
            }
          >
            View on explorer
          </div>
        </div>
      </MenuWrapper>
      <MainWrapper>
        {loader ? (
          <div
            style={{
              width: "100%",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Wrapper>
            {Object.keys(userDetails).length !== 0 &&
              !userDetails.error &&
              !userDetails.message && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      marginBottom: "15px",
                    }}
                  >
                    {userDetails?.username}'s Ping Pong dApp
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      marginBottom: "20px",
                      color: "#C1C1DB",
                    }}
                  >
                    Wallet Address: {userAddresses}
                  </div>
                  {/* <div
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "10px",
                    }}
                    onClick={handleLocalStorage}
                  >
                    Reset Ping Pong
                  </div> */}
                </div>
              )}
            {Object.keys(userDetails).length === 0 &&
              (bannerLoader && !isWalletConnected ? (
                <>
                  <img
                    src={OnConnect}
                    style={{
                      width: "100%",
                      maxWidth: "620px",
                      margin: "10px auto 20px auto",
                    }}
                  />
                </>
              ) : (
                isWalletConnected && (
                  <img
                    src={user_has_nft === true ? Banner : AfterConnect}
                    style={{
                      width: "100%",
                      maxWidth: "620px",
                      margin: "10px auto 20px auto",
                      cursor: user_has_nft === true ? "pointer" : "none",
                    }}
                    onClick={() =>
                      user_has_nft === true
                        ? window.open(
                            `https://explorer.testnet.routerchain.dev/address/${
                              srcChain.type === CustomChainType.near
                                ? getRouterAddress(receipientAddress)
                                : getRouterAddress(accountAddress)
                            }`
                          )
                        : ""
                    }
                  />
                )
              ))}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                maxWidth: "620px",
                margin: "auto",
              }}
            >
              <MessageBlock>
                <Header>
                  <Leftcontent>
                    <Title>Source Chain</Title>
                    <MenuSelect
                      srcChain={srcChain}
                      dstChain={dstChain}
                      setSrcChain={setSrcChain}
                      setDstChain={setDstChain}
                      chainList={chainList}
                      value="src"
                    />
                  </Leftcontent>
                  <Rightcontent>
                    <Title>Destination Chain</Title>
                    <MenuSelect
                      srcChain={srcChain}
                      dstChain={dstChain}
                      setSrcChain={setSrcChain}
                      setDstChain={setDstChain}
                      chainList={chainList}
                      value="dst"
                    />
                  </Rightcontent>
                </Header>
                {showReceipientAddress && (
                  <div style={{ padding: "10px", margin: "20px 0px" }}>
                    <NearReceipient
                      placeholder="Destination EVM address"
                      onChange={(e) => setReceipientAddress(e.target.value)}
                      value={receipientAddress}
                    />
                  </div>
                )}
                <Message background={true}>
                  <TextArea
                    color="#011627"
                    placeholder="Type your message here"
                    value={paragraph}
                    onChange={(e) => setParagraph(e.target.value)}
                    background={true}
                  />
                </Message>
                <SubmitButton
                  onClick={handleSubmit}
                  btnColor={
                    accountAddress !== "" && paragraph !== "" && !executionStart
                      ? true
                      : false
                  }
                >
                  <span style={{ textAlign: "center" }}>
                    {accountAddress !== ""
                      ? "Send Message"
                      : "Please connect your wallet"}
                  </span>
                </SubmitButton>
                {requestId !== "" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "24px 0px 0px 0px",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <div
                        style={{
                          padding: "8px",
                          background: "rgba(154, 154, 176, 0.2)",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            `https://explorer.testnet.routerchain.dev/crosschain?searchTerm=${transactionHash}`
                          )
                        }
                      >
                        View Transaction on Explorer
                      </div>
                      {user_has_nft === false && (
                        <div
                          style={{
                            padding: "8px",
                            background: "rgba(154, 154, 176, 0.2)",
                            borderRadius: "8px",
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(
                              `https://explorer.testnet.routerchain.dev/address/${
                                srcChain.type === CustomChainType.near
                                  ? accountAddress
                                  : getRouterAddress(accountAddress)
                              }`
                            )
                          }
                        >
                          View Your NFT
                        </div>
                      )}
                    </div>
                    {user_has_nft === false && (
                      <div
                        style={{
                          color: "green",
                          marginTop: "8px",
                          textAlign: "start",
                          fontSize: "16px",
                        }}
                      >
                        <i>
                          You will get a participation NFT on the Router chain
                          in a few moments.
                        </i>
                      </div>
                    )}
                  </div>
                )}
                <Seperator></Seperator>
                <div style={{ display: "flex" }}>
                  <Text>Destination Chain</Text>
                  <div
                    style={{
                      margin: "10px",
                      padding: "10px",
                      background: "#2D3241",
                      maxWidth: "160px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={chainLogos[dstChain.networkId].networkLogo}
                      width={"20px"}
                      height={"20px"}
                      style={{ margin: "auto 10px" }}
                    />
                    <div>{dstChain.name}</div>
                  </div>
                </div>

                <Message background={false}>
                  <Loader>
                    {loaderDstMessage ? <CircularProgress /> : ""}
                  </Loader>
                  {!loaderDstMessage && (
                    <TextArea
                      color="black"
                      placeholder={message}
                      disabled
                      background={false}
                    />
                  )}
                </Message>
                <Seperator></Seperator>
                <div style={{ display: "flex" }}>
                  <Text>Acknowledgement Chain</Text>
                  <div
                    style={{
                      margin: "10px",
                      padding: "10px",
                      background: "#2D3241",
                      maxWidth: "160px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={chainLogos[srcChain.networkId].networkLogo}
                      width={"20px"}
                      height={"20px"}
                      style={{ margin: "auto 10px" }}
                    />
                    <div>{srcChain.name}</div>
                  </div>
                </div>
                <Message background={false}>
                  <Loader>{loaderAck ? <CircularProgress /> : ""}</Loader>
                  {!loaderAck && (
                    <TextArea
                      color="black"
                      placeholder={ack}
                      disabled
                      background={false}
                    />
                  )}
                </Message>
                <div ref={bottomPage} />
              </MessageBlock>
              {/* <LeftContent>
                    
                    
                    
                </LeftContent>
                <RightContent>
                    <Header>
                        <Title>
                            Select Chain
                        </Title>
                        <MenuSelect
                            srcChain={srcChain}
                            dstChain={dstChain}
                            setSrcChain={setSrcChain}
                            setDstChain={setDstChain}
                            chainList={chainList}
                            value='dst'
                        />
                    </Header>
                    <Message>
                        <Loader>
                            {loaderDstMessage ? <CircularProgress /> : ''}
                        </Loader>
                        {!loaderDstMessage &&
                            <TextArea
                                color='black'
                                placeholder={message}
                                disabled
                            />
                        }
                    </Message>
                </RightContent> */}
            </div>
          </Wrapper>
        )}
      </MainWrapper>
    </>
  );
};

export default Content;
