import React from 'react'
import styled from 'styled-components'
import { NetworkType } from '../config/network'
import router from '../assets/images/RouterP.svg';
import WalletComponent from './Wallet';

const Wrapper = styled.div`
    padding: 25px 90px;
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    background: #252836;
    @media (max-width: 768px) {
      padding: 25px 50px
    }
    @media (max-width: 500px) {
      padding: 25px 25px
    }
    @media (max-width: 400px) {
      padding: 25px 10px
    }
`

const Title = styled.div`
    font-size: 30px;
    font-weight: 600;
`

const Logo = styled.div`
  display: flex;
`

// const Wallet = styled.div`
//     border: 1px solid #FFFFFF;
//     border-radius: 10px;
//     padding: 5px 10px;
//     display: flex;
//     justify-content: center;
//     cursor: pointer;
//     :hover{
//         background-color: #C3C5CB;
//     }
// `

interface CustomProps {
    setNetworkId: (e: string) => void;
    setCurrentNetwork: (e: NetworkType | '') => void;
  }

const Headers = ({setNetworkId, setCurrentNetwork}: CustomProps) => {
  return (
    <Wrapper>
      <Logo>
        <img src={router} style={{ margin: "auto 0" }} />
        <div
          style={{
            marginLeft: "5px",
            display: "flex",
            width: "100px",
            margin: "0 10px",
            fontSize: "20px",
            fontWeight: "275",
            marginTop: "5px",
          }}
        >
          Ping Pong
        </div>
      </Logo>
      <WalletComponent />
    </Wrapper>
  );
}

export default Headers
