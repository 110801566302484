export * from "./injected";
export * from "./near";

export function shortenAddress(address: string, chars = 8): string {
  return `${address.substring(0, chars + 2)}...${address.substring(
    address.length - chars
  )}`;
}

export const checkIfRouterChainId = (chainId: string) => {
  switch (chainId) {
    case "9600":
      return true;
    case "9601":
      return true;
    case "9602":
      return true;
    case "9603":
      return true;
    case "9604":
      return true;
    case "9605":
      return true;
    default:
      return false;
  }
};