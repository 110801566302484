//@ts-nocheck
import { ethers } from "ethers";
import { EthereumChainId, getEthereumChainIdForNetwork, getNetworkType } from "@routerprotocol/router-chain-sdk-ts";
import { PING_PONG_ENVIRONMENT } from "../config/config";
import { ROUTER_CHAIN_RPC } from "../config/constants";
const { ethereum } = window;

export const getAccountsAddress = async () => {
  try {
    //@ts-ignore
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    //@ts-ignore
    return accounts[0];
  } catch (err) {
    console.log("Error: ", err?.message);
  }
};

export const getMetamaskConnectionState = () => {
  if (typeof window.ethereum !== "undefined") return true;
  else return false;
};

export const getRpcConnectionState = () => ethereum?.isConnected(); //Returns true if the provider is connected to the current chain, and false otherwise.

let params: any;

// paramsTestnet[0] is insignificant, it is there as a place  holder
const paramsTestnet = [
  {
    chainId: "0x13881",
    chainName: "Mumbai Testnet",
    rpcUrls: ["https://rpc.ankr.com/polygon_mumbai"],
    iconUrls: ["https://cryptologos.cc/logos/polygon-matic-logo.png?v=010"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    nativeCurrency: {
      name: "Matic Token",
      symbol: "MATIC",
      decimals: 18,
    },
  },
  {
    chainId: "0xa869",
    chainName: "Avalanche FUJI C-Chain",
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    iconUrls: ["https://cryptologos.cc/logos/huobi-token-ht-logo.png?v=010"],
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
  },
  {
    chainId: "0x61",
    chainName: "BSC Testnet",
    rpcUrls: ["https://rpc.ankr.com/bsc_testnet_chapel"],
    iconUrls: ["https://cryptologos.cc/logos/binance-coin-bnb-logo.png?v=010"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
    nativeCurrency: {
      name: "BNB Token",
      symbol: "BNB",
      decimals: 18,
    },
  },
  {
    chainId: "0x05",
    chainName: "Goerli",
    rpcUrls: ["https://rpc.ankr.com/eth_goerli"],
    iconUrls: ["https://cryptologos.cc/logos/binance-coin-bnb-logo.png?v=010"],
    blockExplorerUrls: ["https://goerli.etherscan.io"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "GoerliETH",
      decimals: 18,
    },
  },
  {
    chainId: "0x14a33",
    chainName: "Base",
    rpcUrls: ["https://goerli.base.org"],
    iconUrls: [
      "https://raw.githubusercontent.com/router-protocol/router-widget/main/exta-icons/Base_Icon_Primary.svg",
    ],
    blockExplorerUrls: ["https://goerli.basescan.org"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
  },
  {
    chainId: "0x" + getEthereumChainIdForNetwork(getNetworkType(PING_PONG_ENVIRONMENT)).toString(16),
    chainName: `Router ${PING_PONG_ENVIRONMENT}`,
    rpcUrls: [ROUTER_CHAIN_RPC],
    iconUrls: [
      "https://raw.githubusercontent.com/router-protocol/router-widget/main/exta-icons/Base_Icon_Primary.svg",
    ],
    blockExplorerUrls: ["https://explorer.testnet.routerchain.dev/"],
    nativeCurrency: {
      name: "Route",
      symbol: "Route",
      decimals: 18,
    },
  },
  {
    chainId: "0x66EED",
    chainName: "Arbitrum Testnet",
    rpcUrls: ["https://arbitrum-goerli.publicnode.com"],
    iconUrls: [
      "https://cryptologos.cc/logos/polygon-matic-logo.png?v=010",
    ],
    blockExplorerUrls: ["https://testnet.arbiscan.io/"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  },
  {
    chainId: "0x1389",
    chainName: "Mantle Testnet",
    rpcUrls: ["https://rpc.testnet.mantle.xyz"],
    iconUrls: [
      "https://cryptologos.cc/logos/polygon-matic-logo.png?v=010",
    ],
    blockExplorerUrls: ["https://explorer.testnet.mantle.xyz"],
    nativeCurrency: {
      name: "MNT",
      symbol: "BIT",
      decimals: 18,
    },
  },
  {
    chainId: "0x82751",
    chainName: "Scroll Alpha",
    rpcUrls: ["https://scroll-testnet.blockpi.network/v1/rpc/public"],
    iconUrls: [
      "https://cryptologos.cc/logos/polygon-matic-logo.png?v=010",
    ],
    blockExplorerUrls: ["https://blockscout.scroll.io"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  },
  {
    chainId: "0xC3",
    chainName: "X1 Testnet",
    rpcUrls: ["https://x1-testnet.blockpi.network/v1/rpc/public"],
    iconUrls: [
      "https://seeklogo.com/images/O/okex-logo-5E39CD0FB6-seeklogo.com.png",
    ],
    blockExplorerUrls: ["https://www.oklink.com/x1-test"],
    nativeCurrency: {
      name: "OKB",
      symbol: "OKB",
      decimals: 18,
    },
  },
  {
    chainId: "0x7CE",
    chainName: "Kyoto Testnet",
    rpcUrls: ["https://rpc.testnet.kyotoprotocol.io:8545"],
    iconUrls: [
      "https://kyotoprotocol.io/hubfs/logo-window-1.png",
    ],
    blockExplorerUrls: ["https://testnet.kyotoscan.io"],
    nativeCurrency: {
      name: "KYOTO",
      symbol: "KYOTO",
      decimals: 18,
    },
  },
  {
    chainId: "0x2803",
    chainName: "Arthera Testnet",
    rpcUrls: ["https://rpc-test.arthera.net"],
    iconUrls: [
      "https://avatars.githubusercontent.com/u/125429157?s=200&v=4",
    ],
    blockExplorerUrls: ["https://explorer-test.arthera.net"],
    nativeCurrency: {
      name: "AA",
      symbol: "AA",
      decimals: 18,
    },
  }

  // {
  //     "chainId": "0x100",
  //     "chainName": "Huobi Testnet",
  //     "rpcUrls": [
  //         "https://http-testnet.hecochain.com"
  //     ],
  //     "iconUrls": [
  //         "https://cryptologos.cc/logos/huobi-token-ht-logo.png?v=010"
  //       ],
  //     "blockExplorerUrls": [
  //         "https://testnet.hecoinfo.com/"
  //     ],
  //     "nativeCurrency": {
  //         "name": "Huobi Token",
  //         "symbol": "HT",
  //         "decimals": 18
  //     },
  // },
  // {
  //     "chainId": "0xa869",
  //     "chainName": "Avalanche FUJI C-Chain",
  //     "rpcUrls": [
  //         "https://api.avax-test.network/ext/bc/C/rpc"
  //     ],
  //     "iconUrls": [
  //         "https://cryptologos.cc/logos/huobi-token-ht-logo.png?v=010"
  //       ],
  //     "blockExplorerUrls": [
  //         "https://cchain.explorer.avax-test.network/"
  //     ],
  //     "nativeCurrency": {
  //         "name": "AVAX",
  //         "symbol": "AVAX",
  //         "decimals": 18
  //     },
  // },
  // {
  //     "chainId": "0x41",
  //     "chainName": "OKExChain Testnet",
  //     "rpcUrls": [
  //         "https://exchaintestrpc.okex.org"
  //     ],
  //     "iconUrls": [
  //         "https://cryptologos.cc/logos/huobi-token-ht-logo.png?v=010"
  //       ],
  //     "blockExplorerUrls": [
  //         "https://www.oklink.com/okexchain-test/"
  //     ],
  //     "nativeCurrency": {
  //         "name": "OKT",
  //         "symbol": "OKT",
  //         "decimals": 18
  //     },
  // },
];

// paramsMainnet[0] is insignificant, it is there as a place  holder

params = paramsTestnet

// export const switchNetworkInMetamask = async (id: string) => {
//   try {
//     if (ethereum) {
//       if (id in ChainId && parseInt(id) === 5) {
//         console.log("here: ", id)
//         await ethereum.request({
//           method: 'wallet_switchEthereumChain',
//           params: [{ chainId: id }]
//         })
//       }
//       else {
//         console.log('non evm')
//         await ethereum.request({
//           method: 'wallet_addEthereumChain',
//           params: [chainLookUp[id]]
//         })
//       }
//       return id
//     }
//   } catch (e: any) {
//     if (e.code === 4902) {
//       try {
//         console.log('non ethereum')
//         await ethereum.request({
//           method: 'wallet_addEthereumChain',
//           params: [chainLookUp[id]]
//         })
//       } catch (addError) {
//         console.log('Error adding Chain');
//       }
//     }
//     if (e.code === 4001) {
//       // EIP-1193 userRejectedRequest error
//       console.log('We can encrypt anything without the key.');
//     } else {
//       console.error(e);
//     }
//   }
// }


export const switchNetworkInMetamask = async (id: string) => {
  console.log('id', id)
  try {
    if (ethereum) {
      // @ts-ignore
      if (id === '3') {
        const formattedChainId = ethers.utils.hexStripZeros(ethers.BigNumber.from(params[Number(id)]['chainId']).toHexString())
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: formattedChainId }]
        })
        return true
      }
      console.log('switch non-ethereum chains')
      console.log('params', [params[Number(id)]])
      try{
        await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [params[Number(id)]], // you must have access to the specified account
      })
      return true
      } catch(e){
        console.log('error while switching', e)
      }
      
    }
    return false;
  } catch (e) {
    if (e.code === 4001) {
      // EIP-1193 userRejectedRequest error
      console.log('We can encrypt anything without the key.');
    } else {
      console.error(e);
    }
    return false
  }
}

