import { chains } from "./network";

const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: item,
    }),
    {}
  );

const chainLookUp = groupBy(chains, "networkId");

export default chainLookUp;

    // {
    //   "name": "Near Testnet",
    //   "type": "near",
    //   "networkId": "near-testnet",
    //   "id": "9",
    //   "endpoint": "https://rpc.testnet.near.org",
    //   "socket": "",
    //   "gatewayContract": "gateway-1687862701420.routerprotocol.testnet",
    //   "PingPong": "ping-pong-1687870174098.routerprotocol.testnet",
    //   "XERC1155": ""
    // }


    // {
    //   "name": "Scroll Testnet",
    //   "type": "ethereum",
    //   "networkId": "534353",
    //   "id": "8",
    //   "endpoint": "https://scroll-testnet.blockpi.network/v1/rpc/public",
    //   "socket": "",
    //   "gatewayContract": "",
    //   "PingPong": "0x4116CCEa4CfbF8c317d969D68090C3de90DAE67b",
    //   "XERC1155": ""
    // }