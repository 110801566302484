import { ChainGrpcWasmApi, toUtf8 } from "@routerprotocol/router-chain-sdk-ts";
import { getRequestMetadata } from "./flag";
import { connect, Contract } from "near-api-js";
import { nearNetworkConfig } from "../components/Wallet/configs/nearConfig";

const ethers = require("ethers");
const near = require("near-api-js");

const CONTRACT_PATH = "./contracts/abis";

const ContractABIs = {
  PingPong: require(CONTRACT_PATH + "/PingPong.json"),
};

//let provider = new ethers.providers.JsonRpcProvider(PROVIDER);
//let wallet = new ethers.Wallet(PRIVATE_KEY, provider);

const sendMessage = async (args) => {
  const pingPongInstance = new ethers.Contract(
    args.srcChainPingPongAddress,
    ContractABIs.PingPong.abi,
    args.signer
  );
  // console.log('srcGasPrice', srcGasPrice.toString())
  let ackGasPrice;
  try {
    ackGasPrice = await args.provider.getGasPrice();
  } catch (e) {
    console.log("error in fetching ack gas price", e);
    return;
  }
  const newAckGasPrice = ackGasPrice.mul(13).div(10);
  console.log("newDstGasPrice", args.destGasPrice);
  console.log("newAckGasPrice", newAckGasPrice.toString());

  // const requestArgs = [
  //   '10000000000000',
  //   false,
  //   '0'
  // ]
  const requestMetadata = getRequestMetadata(
    args.chainId === "421613" ? 15000000 : 250000,
    args.destGasPrice,
    args.srcChainId === "421613" ? 15000000 : 250000,
    newAckGasPrice.toString(),
    "0",
    3,
    false,
    ""
  );

  console.log("rewuesstNetadata", requestMetadata);

  const result = await pingPongInstance.iPing(
    args.chainId,
    args.destContractAddress,
    args.message,
    requestMetadata
  );
  const value = await result.wait();
  console.log("value", value);
  return value
};

const fetchMessage = async (args) => {
  console.log("fetch args", args);
  try {
    const pingPongInstance = new ethers.Contract(
      args.destContractAddress,
      ContractABIs.PingPong.abi,
      args.provider
    );
    const result = await pingPongInstance.pingFromSource(
      args.chainId,
      args.requestId
    );
    return result;
  } catch (error) {
    console.log("Error in fetch message", error);
  }
};

const getAck = async (args) => {
  console.log("getAck args", args);
  try {
    const pingPongInstance = new ethers.Contract(
      args.srcChainPingPongAddress,
      ContractABIs.PingPong.abi,
      args.provider
    );
    const result = await pingPongInstance.ackFromDestination(args.requestId);
    console.log("result", result);
    return result;
  } catch (e) {
    console.log("error in acknowledement", e);
  }
};

const nftData = async (args) => {
  console.log("args", args);
  try {
    const response = await args.wasmClient.fetchSmartContractState(
      args.nftRouterAddress,
      toUtf8(JSON.stringify(args.query))
    );
    console.log("response: ", response);
    return response;
  } catch (error) {
    console.log("error in wasm", error);
    return null;
  }
};

const getAckRouterChain = async (grpc, contractAddress, requestId) => {
  const wasmClient = new ChainGrpcWasmApi(grpc);
  try {
    const fetchSmartContractStateResult =
      await wasmClient.fetchSmartContractState(
        contractAddress,
        toUtf8(
          JSON.stringify({
            fetch_pong: { request_id: parseInt(requestId) },
          })
        )
      );
    console.log(
      "fetchSmartContractStateResult =>",
      fetchSmartContractStateResult.data
    );
    return fetchSmartContractStateResult.data;
  } catch (e) {
    console.log("error in getAckRouterChain ->", e);
  }
};

const getAccountsDetailsNear = async (accountId) => {
  const nearConnection = await connect(nearNetworkConfig);
  const account = await nearConnection.account(accountId);
  console.log("Near Acccount details =>", account);
  return account;
};

async function readFromNear(
  accountId,
  contractId,
  methodName,
  methodArgs = {}
) {
  try {
    /*
    Read Fuctions 
    "get_ping_from_source"
    {
      src_chain_id: "80001",
      request_id: 1,
    }
    "get_ack_from_destination"
    {
     request_id : 1,
    }
    */

    // connect to NEAR
    console.log("methodArgs =>", methodArgs);
    console.log("methodName =>", methodName);
    console.log("contractId =>", contractId);
    const account = await getAccountsDetailsNear(accountId);

    const contract = new Contract(account, contractId, {
      viewMethods: [methodName],
      changeMethods: [],
    });
    const response = await contract[methodName](methodArgs);
    console.log("response =>", JSON.stringify(response));
    return response;
  } catch (e) {
    console.log("readFromNear error ", e);
  }
}

async function getNearRequestId(txHash, accountId, rpc) {
  try {
    const provider = new near.providers.JsonRpcProvider(rpc);
    const responseWithString = await provider.txStatus(txHash, accountId);
    let request_id = "";
    responseWithString.receipts_outcome.forEach((x) => {
      const logs = x.outcome.logs;
      //@ts-ignore
      if (logs.length > 0 && logs[0].includes('"request_id":')) {
        let a = logs[0].split('"request_id":');
        let b = a[1].split("}");
        request_id = b[0];
        return;
      }
    });
    console.log("request_id =>", request_id);
    return request_id;
  } catch (e) {
    console.log("getNearRequestId error ", e);
  }
}

const fetchMessageRouterChain = async (
  grpc,
  contractAddress,
  srcChainId,
  requestId
) => {
  const wasmClient = new ChainGrpcWasmApi(grpc);
  try {
    const fetchSmartContractStateResult =
      await wasmClient.fetchSmartContractState(
        contractAddress,
        toUtf8(
          JSON.stringify({
            fetch_ping: {
              chain_id: srcChainId,
              request_id: parseInt(requestId),
            },
          })
        )
      );
    console.log(
      "fetchSmartContractStateResult =>",
      fetchSmartContractStateResult.data
    );
    return fetchSmartContractStateResult.data;
  } catch (e) {
    console.log("error in fetchMessageRouterChain ->", e);
  }
};

export {
  sendMessage,
  fetchMessage,
  getAck,
  nftData,
  getAckRouterChain,
  readFromNear,
  getNearRequestId,
  fetchMessageRouterChain,
};
