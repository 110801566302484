import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { NetworkType } from "../config/network";
import { switchNetworkInMetamask } from "../utils/metamaskFunctions";
import { chainLogos } from "../config/flag";
import useWindowDimensions from "../hooks/useScreenDimension";
import { CustomChainType, WalletId } from "./Wallet/types";
import { useWalletId } from "../state/wallet/hooks";

interface CustomProps {
  srcChain: NetworkType;
  setSrcChain: (e: NetworkType) => void;
  dstChain: NetworkType;
  setDstChain: (e: NetworkType) => void;
  value: string;
  chainList: NetworkType[];
}

export default function MenuSelect({
  srcChain,
  setSrcChain,
  dstChain,
  setDstChain,
  value,
  chainList,
}: CustomProps) {
  const [defaultSrcChain, setDefaultSrcChain] = React.useState(srcChain.name);
  const [defaultDstChain, setDefaultDstChain] = React.useState(dstChain.name);
  const [destFilter, setDestFilter] = React.useState(chainList);
  const screenWidth = useWindowDimensions();
  const [walletId] = useWalletId();

  const changeChain = async (e: any, filterChain: any) => {
    if (walletId !== WalletId.injected) {
      setSrcChain(e);
      setDstChain(filterChain);
      return;
    }
    if (e.type === CustomChainType.near) {
      setSrcChain(e);
      setDstChain(filterChain);
      return;
    }
    try {
      const res = await switchNetworkInMetamask(e.id);
      if (res) {
        console.log("in", res);
        setSrcChain(e);
        setDstChain(filterChain);
      }
    } catch (e) {
      console.log("error in switching");
    }
  };

  const handleSrcChange = (e: SelectChangeEvent) => {
    const chainName = e.target.value;
    // setDefaultSrcChain(chainName)
    const filterChain = chainList.filter(
      (i: NetworkType) => i.name === chainName
    )[0];
    // setSrcChain(filterChain)
    const chain = chainList.filter(
      (i) => i.networkId !== filterChain.networkId
    );
    // setDstChain(chain[0])
    changeChain(filterChain, chain[0]);
    // switchNetworkInMetamask(filterChain.id)
  };

  const handleDstChange = (e: SelectChangeEvent) => {
    const chainName = e.target.value;
    const filterChain = chainList.filter(
      (i: NetworkType) => i.name === chainName
    )[0];
    setDstChain(filterChain);
  };

  React.useEffect(() => {
    setDefaultSrcChain(srcChain.name);
  }, [srcChain]);

  React.useEffect(() => {
    setDefaultDstChain(dstChain.name);
  }, [dstChain]);

  React.useEffect(() => {
    if (value === "dst") {
      const filter = chainList.filter(
        (i) => i.networkId !== srcChain.networkId
      );
      setDestFilter(filter);
    }
  }, [value, srcChain, chainList]);

  return (
    <Box
      sx={{
        width: screenWidth.width <= 500 ? "180px" : "200px",
        background: "#2D3241",
        border: "10px",
        color: "#C1C1DB",
      }}
    >
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value === "src" ? defaultSrcChain : defaultDstChain}
          onChange={value === "src" ? handleSrcChange : handleDstChange}
          style={{
            color: "#FFFFFF",
            border: "1px solid rgba(88, 88, 99, 0.24)",
            outline: "none",
          }}
          sx={{
            ".MuiSvgIcon-root ": {
              color: "#C1C1DB !important",
            },
            ".css-qiwgdb.css-qiwgdb.css-qiwgdb": {
              fontSize: screenWidth.width <= 500 ? "13px !important" : "16px",
            },
            ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                fontSize: screenWidth.width <= 500 ? "13px !important" : "16px",
              },
          }}
        >
          {(value === "src" ? chainList : destFilter).map((i: NetworkType) => {
            return (
              <MenuItem value={i.name}>
                <div style={{ display: "flex" }}>
                  <img
                    src={
                      chainLogos[i.networkId]
                        ? chainLogos[i.networkId].networkLogo
                        : "😎"
                    }
                    width={"20px"}
                    height={"20px"}
                    alt="😎"
                    style={{ margin: "auto 10px" }}
                  />
                  <div>{i.name}</div>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
