import numeral from "numeral"


export function shortenAddress(address: string, chars = 4): string {
  return `${address.substring(0, chars + 2)}...${address.substring(42 - chars)}`
}
export function shortenTxn(address: string, chars = 4): string {
  if (!address) return ""
  return `${address.substring(0, chars + 2)}...${address.substring(address.length - chars)}`
}


export const numeralformat = (value: any) => {
  if (value === '-') return '-'
  else return numeral(value).format('0.00a').toUpperCase()
}

export const numeralFormatNumber = (value: any) => {
  if (value === '-') return '-'
  else return numeral(value).format()
}

export const fixedDecimalPlace = (value: any, decimalPlace: number) => {
  if (value === '-') return '-'
  else return getFlooredFixed(parseFloat(value.toString()), decimalPlace)
}

export function getFlooredFixed(v: any, d: number) {
  v = v ? Number(v) : 0
  return (parseFloat((Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d)) * 1).toString();
}

export const getChainNameById = (chainId: string) => {
  switch (chainId) {
    case "1": return 'ethereum';
    case "42": return 'kovan';
    case "250": return 'fantom';
    case "137": return 'polygon';
    case "80001": return 'mumbai';
    case "56": return 'smartchain';
    default: return '';
  }
}

function componentToHex(c: any) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r: any, g: any, b: any) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const rgbToHexColor = (color: any) => {
  if (color.charAt(0) === "#") return color
  color = color.split('(')
  color = color[1].split(")")
  color = color[0].split(",")
  return rgbToHex(Number(color[0]), Number(color[1]), Number(color[2]))
}

export function adjustColor(color: string, amount: number) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}