import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { EthereumChainId } from "@routerprotocol/router-chain-sdk-ts";

interface WalletState {
  accountAddress: string;
  networkId: string;
  walletConnected: boolean;
  showConnectWallet: boolean;
  walletId: string;
  chainType: any;
}

const intialWalletId =
  typeof window !== "undefined"
    ? localStorage.getItem("walletId")
      ? JSON.parse(localStorage.getItem("walletId") || "{}")
      : ""
    : "";

export const RpcWsUrlsForChainIds = {
  [EthereumChainId.Ganache]: "ws://localhost:1318",
  [EthereumChainId.HardHat]: "ws://localhost:1318",
  [EthereumChainId.Goerli]: `wss://eth-goerli.g.alchemy.com/v2/lKoEGqrjvq8c6u5Mk_cwM1uVck-eMSo9`,
  [EthereumChainId.Mainnet]: `wss://eth-mainnet.g.alchemy.com/v2/CXo5phLTfcA4RVBMcXbhtK4IkIY7928Q`,
  [EthereumChainId.AlphaDevnetEvm]: `wss://devnet-alpha.evm.rpc.routerprotocol.com/`,
  [EthereumChainId.DevnetEvm]: `wss://devnet-alpha.evm.rpc.routerprotocol.com/`,
  [EthereumChainId.TestnetEvm]: `wss://devnet-alpha.evm.rpc.routerprotocol.com/`,
};

const initialState: WalletState = {
  accountAddress: "",
  networkId: "",
  walletConnected: false,
  showConnectWallet: false,
  walletId: intialWalletId,
  chainType: null,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setAccountAddress: (state: WalletState, action: PayloadAction<string>) => {
      state.accountAddress = action.payload;
    },
    setNetworkId: (state: WalletState, action: PayloadAction<string>) => {
      state.networkId = action.payload;
    },
    setWalletConnected: (
      state: WalletState,
      action: PayloadAction<boolean>
    ) => {
      state.walletConnected = action.payload;
    },
    setShowConnectWallet: (
      state: WalletState,
      action: PayloadAction<boolean>
    ) => {
      state.showConnectWallet = action.payload;
    },
    setWalletId: (state: WalletState, action: PayloadAction<string>) => {
      state.walletId = action.payload;
      localStorage.setItem("walletId", JSON.stringify(action.payload));
    },
    setChainType: (state: WalletState, action: PayloadAction<string>) => {
      state.chainType = action.payload;
    },
  },
});

export const {
  setAccountAddress,
  setNetworkId,
  setWalletConnected,
  setWalletId,
  setShowConnectWallet,
  setChainType,
} = walletSlice.actions;

export const accountAddress = (state: RootState) => state.wallet.accountAddress;
export const networkId = (state: RootState) => state.wallet.networkId;
export const walletConnected = (state: RootState) =>
  state.wallet.walletConnected;
export const showConnectWallet = (state: RootState) =>
  state.wallet.showConnectWallet;
export const walletId = (state: RootState) => state.wallet.walletId;
export const chainType = (state: RootState) => state.wallet.chainType;

export default walletSlice.reducer;
